
import IComponentProps from "../../../interfaces/IComponentProps";
import Promotional from "../../components/Promotional/Promotional";
import BlogNewsEventsPageModel from '../../../models/BlogNewsEventsPageModel';
import BlogNewsEvents from '../../components/BlogNewsEvents/BlogNewsEvents';
import BlogNewsEventModel from '../../../models/BlogNewsEventModel';
import BlogNewsEventsContent from '../../components/BlogNewsEvents/BlogNewsEventsContent';
import CategoryCardList from '../../components/CategoryCardList/CategoryCardList';
import Disclosures from '../../components/Disclosures/Disclosures';
import PromotionModel from '../../../models/PromotionModel';
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import ContentfulReactRenderOptions from "../../_shared/ContentfulReactRenderOptions";
import Feature from "../../components/Feature/Feature";
import styling from "../../components/BlogNewsEvents/BlogNewsEvents.module.scss";
import BlogNewsEventsItemModel from "../../../models/BlogNewsEventsItemModel";

export default function BlogNewsEventsPage(props: BlogNewsEventsPageModel & IComponentProps){
    const { htmlId, className, hero, bneItems = [[]], contents, content, defaultTab} = props;
    const { relatedTools, disclosures, additionalContent, feature } = content ?? {};
    console.log("BlogNewsEventsPage props", props);
    return (
        <div id={htmlId} className={className}>
            {!content && <section>
                {hero && <Promotional {...new PromotionModel(hero)}/>}
            </section>}
            {!content && <section>
                <BlogNewsEvents {...new BlogNewsEventModel({bneItems, contents, defaultTab})}/>
            </section>}
            {content && <section>
                {/* @ts-ignore */}
                <BlogNewsEventsContent {...new BlogNewsEventsItemModel(props)} 
                 title={content?.body?.title}
                 />
            </section>
            }
            {feature && <section>
                <Feature {...feature} />
            </section>
            }
            {additionalContent && <section className={`${styling['content-block']} pt-5 ${styling['bg-white']}`}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-2 d-none d-lg-block" />
                        <div className="col-lg-8">
                            {documentToReactComponents(additionalContent, ContentfulReactRenderOptions)}
                        </div>
                        <div className="col-lg-2 d-none d-lg-block" />
                    </div>
                </div>
            </section>
            }
            {relatedTools && <section>
                <CategoryCardList {...relatedTools} />
            </section>
            }
            <section>
                <Disclosures disclosures={disclosures} />
            </section>
        </div>
    );
}
